
import Vue from 'vue';
// import BaseFooter from './components/base/BaseFooter.vue';
import ClickAudio from './components/base/ClickAudio.vue';

export default Vue.extend({
  components: { ClickAudio },
  name: 'App',

  data: () => ({}),
  mounted() {
    ((this.$refs.app as Vue).$el as HTMLElement).addEventListener(
      'touchend',
      (e) => {
        if (
          e &&
          e.target &&
          e.target instanceof HTMLElement &&
          !e.target.classList.contains('v-btn__content')
        ) {
          e.preventDefault();
          e.target.click();
        }
      },
    );
  },

  // destroyed() {},
});
